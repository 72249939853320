<template>
  <div class="leads list">
    <CRow>
      <CCol sm="12">
        <div class="list__actions">
          <CButton  color="info" :onChange="isLoading" v-on:click="reloadData()"><CIcon name="cil-sync" />Actualizar datos</CButton>
        </div>
        <div class="list__table">
          <CDataTable
            :items="leads"
            :fields="fields"
            :items-per-page="25"
            :columnFilter="true"
            :items-per-page-select="{label: 'Items por página', values: [25, 50, 100, 500, 1000]}"
            :tableFilter="{ external: false, lazy: true, placeholder: 'Filtrar leads', label: 'Filtro de tabla' }"
            :sorterValue="{ asc: true }"
            :sorter='{ external: false, resetable: true }'
            :loading="isLoading"
            pagination
          >
          <template #reparacion_price="{item}">
            <td>
              <span>${{item.reparacion_price.toLocaleString()}}</span>
            </td>
          </template>
          <template #createdAt="{item}">
            <td>
              <span>{{new Date(item.createdAt).getDate() + '/' +(new Date(item.createdAt).getMonth() + 1 )+ '/'+new Date(item.createdAt).getFullYear()+ ' ' +  new Date(item.createdAt).getHours() + ':' + new Date(item.createdAt).getMinutes()}}</span>
            </td>
          </template>
          <template #presupuesto_enviado="{item}">
            <td>
              <div class="badge-container">
                <CBadge :color="getBadge(item.presupuesto_enviado)">{{parseEstadoEmail(item.presupuesto_enviado)}}</CBadge>
              </div>
            </td>
          </template>
          <template #seguimiento_enviado="{item}">
            <td>
              <div class="badge-container">
                <CBadge :color="getBadge(item.seguimiento_enviado)">{{parseEstadoEmail(item.seguimiento_enviado)}}</CBadge>
              </div>
              
            </td>
          </template>
          <template #venta_confirmada="{item}">
            <td>
              <div class="badge-container">
                <CBadge :color="getBadge(item.venta_confirmada)">{{parseEstadoVenta(item.venta_confirmada)}}</CBadge>
              </div>
            </td>
          </template>
          <template #acciones="{item}">
            <td>
              <CButtonGroup >
                <CButton v-on:click="triggerReenviarMails(item)" v-c-tooltip="'Reenviar mails'" color="info"><CIcon name="cil-envelope-closed" /></CButton>
                <CButton data-coreui-toggle="tooltipConfirmar" v-on:click="triggerConfirmarVenta(item)" v-c-tooltip="'Gestionar venta'" v-if="!item.venta_confirmada" color="success"><CIcon name="cil-dollar" /></CButton>
                <CButton data-coreui-toggle="tooltipConfirmar" v-on:click="triggerCancelarVenta(item)" v-c-tooltip="'Gestionar venta'" v-if="item.venta_confirmada" color="warning"><CIcon name="cil-ban" /></CButton>
                <!--  <CButton v-c-tooltip="'Eliminar Lead'" color="danger"><CIcon name="cil-x-circle" /></CButton>-->
              </CButtonGroup>
            </td>
          </template>
          </CDataTable>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import leadsService from '../../services/leads.service';
const Swal = require('sweetalert2');
const leadService = require('../../services/leads.service');

export default {
  name: 'Leads',
  data() {
    return {
      fields: this.generateFields(),
      leads: [{}],
      isLoading: true,
    }
  },
  created() {
    this.getLeads();
  },
  updated() {

  },
  methods: {
    getLeads: function() {
      leadService.getLeads(this.$store.state.user.data.id).then((response) => {
        // handle success
        this.leads = response.data.leads;
        this.isLoading = false;
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },
    reloadData: function() {
      this.isLoading = true;
     this.getLeads();
    },
    generateFields(){
      const fields = [{
        key: 'nombre' ,
        label: 'Nombre',
      },{
        key: 'email' ,
        label: 'Email',
      },{
        key: 'tel' ,
        label: 'Teléfono',
      },{
        key: 'modelo_name' ,
        label: 'Modelo',
      },{
        key: 'reparacion_name' ,
        label: 'Problema',
      },{
        key: 'reparacion_price' ,
        label: 'Valor',
      },{
        key: 'createdAt' ,
        label: 'Fecha',
        filter: false,
      },{
        key: 'tipo_respuesta' ,
        label: 'Respuesta enviada',
      },{
        key: 'presupuesto_enviado' ,
        label: 'Presupuesto',
        filter: false,
      },{
        key: 'seguimiento_enviado' ,
        label: 'Seguimiento',
        filter: false,
      },{
        key: 'venta_confirmada' ,
        label: 'Vendido',
        filter: false,
      },{
        key: 'acciones' ,
        label: 'Acciones',
        filter: false,
      }];

      return fields;
    },
    getBadge (status) {
      return status === true ? 'success'
          : status === false ? 'danger' : 'secondary'
    },
    parseEstadoEmail (status) {
      return status === true ? 'Enviado'
        : status === false ? 'No enviado' : ''
    },
    parseEstadoVenta (status) {
      return status === true ? 'Sí'
        : status === false ? 'No' : ''
    },
    async triggerReenviarMails(lead){
      const {value: accion} = await Swal.fire({
        title: 'Reenviar Mails',
        html: 'Selecciona el tipo de Mail a Reenviar para <br><strong>' + lead.nombre + '</strong> - <strong>' + lead.modelo_name + '</strong> - <strong>' + lead.reparacion_name + '</strong>',
        input: 'select',
        inputOptions: {
          'Email Presupuesto': 'Email Presupuesto',
        },
        inputPlaceholder: 'Selecciona acá...',
        showCancelButton: true,
      })

      if (accion == 'Email Presupuesto') {
        Swal.fire({
          title: 'Confirmar acción',
          html: `Vas a reenviar el email de presupuesto para: <br>${lead.nombre} - ${lead.modelo_name} - ${lead.reparacion_name} <br><br><strong>¿Estás seguro?</strong>`,
          showDenyButton: true,
          confirmButtonText: `Confirmar`,
          denyButtonText: `Cancelar`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            leadsService.leadReenviarPresupuesto(lead.id).then(() => {
              Swal.fire('¡Operación realizada!', 'Presupuesto en cola para reenviar.', 'success');
            }).catch((error) => {
              // handle error
              console.log(error);
               Swal.fire('Hubo un error', 'Consulte al administrador', 'error')
            });
          } else if (result.isDenied) {
            Swal.fire('Operación cancelada', '', 'error')
          }
        });
      }
    },
    async triggerConfirmarVenta(lead){
      const { value: formValues } = await Swal.fire({
        title: 'Confirmar venta',
        html: `Vas a confirmar la venta para: <br><br>${lead.nombre} - ${lead.modelo_name} - ${lead.reparacion_name} <br><label style="margin-top: 20px;" for="modelo_name">Precio final: <input id="lead_price" type="number" name="lead_price" value="${lead.reparacion_price}" class="swal2-input">`,
        showDenyButton: true,
        preConfirm: () => {
          return {
            price: document.getElementById('lead_price').value,
          }
        },
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      });

      let leadPrice = formValues.price;

      if(!formValues){
        Swal.fire('Operación cancelada', '', 'error')
      }else{
        leadsService.leadConfirmarVenta(lead.id).then(() => {
          
          lead.venta_confirmada = true;

          if(leadPrice != lead.reparacion_price){
            lead.reparacion_price = leadPrice;
            leadsService.updateLead(lead.id, lead).then((result) => {

              Swal.fire('¡Operación realizada!', 'La venta del lead fue confirmada.', 'success');
              this.reloadData();
            });

          }else{

            Swal.fire('¡Operación realizada!', 'La venta del lead fue confirmada.', 'success');

          }

          

        }).catch((error) => {
          // handle error
          console.log(error);
            Swal.fire('Hubo un error', 'Consulte al administrador', 'error')
        });
      }

      


    },
    async triggerCancelarVenta(lead){
      await Swal.fire({
        title: 'Cancelar venta',
        html: `Vas a cancelar la venta para: <br>${lead.nombre} - ${lead.modelo_name} - ${lead.reparacion_name} <br><br><strong>¿Estás seguro?</strong>`,
        showDenyButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          leadsService.leadConfirmarVenta(lead.id).then((result) => {
            Swal.fire('¡Operación realizada!', 'La venta del lead fue cancelada.', 'success');
            lead.venta_confirmada = false;
          }).catch((error) => {
            // handle error
            console.log(error);
              Swal.fire('Hubo un error', 'Consulte al administrador', 'error')
          });
        } else if (result.isDenied) {
          Swal.fire('Operación cancelada', '', 'error')
        }
      });
    }
  }
}
</script>
