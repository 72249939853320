const axios = require('axios');


const leadsService = {

    getLeads(usuario_id) {
        return axios.get(`${process.env.VUE_APP_API_URL}api/leads/usuario/${usuario_id}`,{
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },

    updateLead(lead_id, lead) {
        return axios.put(`${process.env.VUE_APP_API_URL}api/leads/${lead_id}`,lead,{
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },

    leadReenviarPresupuesto(lead_id) {
        return axios.post(`${process.env.VUE_APP_API_URL}api/leads/leadReenviarPresupuesto/${lead_id}`,{
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },

    leadConfirmarVenta(lead_id) {
        return axios.post(`${process.env.VUE_APP_API_URL}api/leads/leadConfirmarVenta/${lead_id}`,{
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },

    leadCancelarVenta(lead_id) {
        return axios.post(`${process.env.VUE_APP_API_URL}api/leads/leadCancelarVenta/${lead_id}`,{
            headers: {
            'x-token-clica': 'agenciaclica123'
            }
        });
    },
}

module.exports = leadsService;