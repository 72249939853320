var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leads list"},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"list__actions"},[_c('CButton',{attrs:{"color":"info","onChange":_vm.isLoading},on:{"click":function($event){return _vm.reloadData()}}},[_c('CIcon',{attrs:{"name":"cil-sync"}}),_vm._v("Actualizar datos")],1)],1),_c('div',{staticClass:"list__table"},[_c('CDataTable',{attrs:{"items":_vm.leads,"fields":_vm.fields,"items-per-page":25,"columnFilter":true,"items-per-page-select":{label: 'Items por página', values: [25, 50, 100, 500, 1000]},"tableFilter":{ external: false, lazy: true, placeholder: 'Filtrar leads', label: 'Filtro de tabla' },"sorterValue":{ asc: true },"sorter":{ external: false, resetable: true },"loading":_vm.isLoading,"pagination":""},scopedSlots:_vm._u([{key:"reparacion_price",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v("$"+_vm._s(item.reparacion_price.toLocaleString()))])])]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(new Date(item.createdAt).getDate() + '/' +(new Date(item.createdAt).getMonth() + 1 )+ '/'+new Date(item.createdAt).getFullYear()+ ' ' + new Date(item.createdAt).getHours() + ':' + new Date(item.createdAt).getMinutes()))])])]}},{key:"presupuesto_enviado",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"badge-container"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.presupuesto_enviado)}},[_vm._v(_vm._s(_vm.parseEstadoEmail(item.presupuesto_enviado)))])],1)])]}},{key:"seguimiento_enviado",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"badge-container"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.seguimiento_enviado)}},[_vm._v(_vm._s(_vm.parseEstadoEmail(item.seguimiento_enviado)))])],1)])]}},{key:"venta_confirmada",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"badge-container"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.venta_confirmada)}},[_vm._v(_vm._s(_vm.parseEstadoVenta(item.venta_confirmada)))])],1)])]}},{key:"acciones",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButtonGroup',[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Reenviar mails'),expression:"'Reenviar mails'"}],attrs:{"color":"info"},on:{"click":function($event){return _vm.triggerReenviarMails(item)}}},[_c('CIcon',{attrs:{"name":"cil-envelope-closed"}})],1),(!item.venta_confirmada)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Gestionar venta'),expression:"'Gestionar venta'"}],attrs:{"data-coreui-toggle":"tooltipConfirmar","color":"success"},on:{"click":function($event){return _vm.triggerConfirmarVenta(item)}}},[_c('CIcon',{attrs:{"name":"cil-dollar"}})],1):_vm._e(),(item.venta_confirmada)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Gestionar venta'),expression:"'Gestionar venta'"}],attrs:{"data-coreui-toggle":"tooltipConfirmar","color":"warning"},on:{"click":function($event){return _vm.triggerCancelarVenta(item)}}},[_c('CIcon',{attrs:{"name":"cil-ban"}})],1):_vm._e()],1)],1)]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }